import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Thumbs } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

Swiper.use([Navigation, Pagination, Thumbs]);

window.Swiper = Swiper


// const sliderSelector = '.swiper-container'
// const defaultOptions = {
//   breakpointsInverse: true,
//   observer: true,
//   loop: true
// }
// const nSlider = document.querySelectorAll(sliderSelector);

// for(const slider of nSlider || []) {
// 	const data = slider.getAttribute('data-swiper') || null;

// 	if (data) {
// 		const dataOptions = JSON.parse(data);
// 		slider.options = Object.assign({}, defaultOptions, dataOptions);
// 	}

// 	const swiper = new Swiper(slider, slider.options);

// 	if (slider?.options?.autoplay && slider.options.autoplay !== false) {
// 		// console.log(slider.options.autoplay)

// 		slider.addEventListener('mouseenter', function(e) {
// 			swiper.autoplay.stop();
// 			console.log('stop')
// 		});

// 		slider.addEventListener('mouseleave', function(e) {
// 			swiper.autoplay.start();
// 			console.log('start')
// 		});
// 	}
// }

/////////////-------------------------------------------------------

// const swiperDefault = new Swiper('.swiper-default ', {
// 	autoplay: {
// 		delay: 3000,
// 	},
// 	slidesPerView: 3,
// 	spaceBetween: 10,

// 	pagination: {
// 		el: '.product-pagination',
// 		clickable: true,
// 		renderBullet: function (index, className) {
// 			return '<span class="' + className + '">' + (index + 1) + "</span>";
// 		},
// 	},
// });